<template>
  <SfCollectedProduct
    class="collected-product"
    :image="transformImageUrlToSize(cartGetters.getItemImage(wishlistItem), ImageSize.Large) || ''"
    :title="productGetters.getName(wishlistItem.product)"
    :regular-price="$formatCurrency(productGetters.getPrice(wishlistItem.product).regular)"
    :special-price="
      productGetters.getPrice(wishlistItem.product).special &&
      $formatCurrency(productGetters.getPrice(wishlistItem.product).special)
    "
    :link="localePath(`/${wishlistItem.product.url_key}.html`)"
    @input="invokeUpdateItemQty({ quantity: $event })"
  >
    <template #remove> {{ null }}</template>
    <template #input>
      <SfBadge v-if="!isInStock(wishlistItem)" class="color-danger sf-badge__absolute">
        <template #default>
          <span>{{ $t('Out of stock') }}</span>
        </template>
      </SfBadge>
      <div v-else>{{ null }}</div>
    </template>
    <template #configuration>
      <!-- bundles -->
      <div v-if="getBundles().length > 0">
        <SfProperty v-for="(bundle, i) in getBundles()" :key="i" :name="`${bundle.quantity}x`" :value="bundle.label" />
      </div>

      <!-- configurable_options -->
      <ul v-if="wishlistItem.configurable_options" class="collected-product__configurable-options">
        <li v-for="(attr, index) in wishlistItem.configurable_options.filter((attr) => attr)" :key="index">
          {{ attr.value_label }}
        </li>
      </ul>

      <SfButton
        v-if="isInStock(wishlistItem)"
        class="sf-collected-product__add-to-cart"
        @click="invokeAddWishlistItemToCart()"
      >
        <SfLoader
          v-if="itemAddingToCart && itemAddingToCart === wishlistItem.id"
          class="sf-collected-product__add-to-cart-loader"
        />
        <BasketIcon v-else />
      </SfButton>

      <SfQuantitySelector
        :min="0"
        :qty="cartGetters.getItemQty(wishlistItem)"
        :ariaLabel="$t('Quantity')"
        @input="
          invokeUpdateItemQty({
            quantity: $event,
          })
        "
      />
    </template>
    <template #actions>{{ null }}</template>
    <template #more-actions>{{ null }}</template>
  </SfCollectedProduct>
</template>
<script setup lang="ts">
import cartGetters from '~/utils/getters/magentoCartGetters'
import productGetters from '~/utils/getters/magentoProductGetters'
import _debounce from '~/utils/debounce'

import { useCart } from '~/composables'

import stockStatusEnum from '~/enums/stockStatusEnum'
import BasketIcon from '~/assets/icons/shopping-bag.svg'
import { storeToRefs } from 'pinia'

const props = defineProps({
  wishlistItem: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['itemAddedToCart'])

const { transformImageUrlToSize, ImageSize } = useMagentoImage()
const wishlistStore = useWishlistStore()
const { removeItem, updateItem, setWishlist, addItemToCart } = wishlistStore
const { wishlist } = storeToRefs(wishlistStore)
const { load: loadCart } = useCart()
const localePath = useLocalePath()

const itemAddingToCart = ref(null)

const getBundles = () => props.wishlistItem?.product?.items?.map((b) => b.title).flat() || []

const delayedUpdateItemQty = _debounce(({ quantity }) => {
  if (quantity === 0) removeItem({ product: props.wishlistItem.product })
  else
    updateItem({
      wishlistItems: [
        {
          wishlist_item_id: props.wishlistItem.id,
          quantity: quantity,
        },
      ],
    })
}, 500)

const invokeAddWishlistItemToCart = async () => {
  itemAddingToCart.value = props.wishlistItem.id

  await addItemToCart({
    wishlistItem: props.wishlistItem,
    callback: (response) => {
      emit('itemAddedToCart', { name: props.wishlistItem?.product.name, response })
    },
  })

  itemAddingToCart.value = null

  loadCart()
}

const invokeUpdateItemQty = ({ quantity }) => {
  const wishlistItemIndex = wishlist.value?.items_v2.items.findIndex((item) => item.id === props.wishlistItem.id)

  // SF-UI issue, doesn't set "zero" quantity -> Check for removal:
  if (quantity === 1 && props.wishlistItem?.quantity === quantity) {
    quantity = 0
  }

  // Set wishlist to match result after response from GraphQL.
  const newWishlist = { ...wishlist.value }
  newWishlist.items_v2.items[wishlistItemIndex] = {
    ...props.wishlistItem,
    quantity,
  }

  setWishlist(newWishlist)
  delayedUpdateItemQty({ quantity })
}

const isInStock = (product) => product?.product && cartGetters.getStockStatus(product) === stockStatusEnum.inStock
</script>

<style lang="scss" scoped>
.my-wishlist {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__total-items {
    margin-top: var(--spacer-sm);
    --property-name-margin: 0 var(--spacer-sm) 0 0;
    --property-name-font-size: var(--font-size--sm);
    --property-name-font-line-height: 1.4286;
    --property-value-font-size: var(--font-size--sm);
    --property-value-font-line-height: 1.4286;
    --property-name-color: var(--black-color);
    --property-value-color: var(--black-color);
    --property-name-font-family: var(--font-family--primary);
    --property-value-font-family: var(--font-family--primary);
    --property-name-font-weight: var(--font-weight--semibold);
    --property-value-font-weight: var(--font-weight--semibold);
  }

  &__total-price {
    --property-name-font-size: var(--font-size--xl);
    --price-font-size: var(--font-size--xl);
    margin: 0 0 var(--spacer-xl) 0;
    &-label {
      font: var(--font-weight--normal) var(--font-size--2xl) / 1.6 var(--font-family--secondary);
      color: var(--c-link);
    }
  }

  &__login-title {
    font-weight: var(--font-weight--semibold);
    line-height: var(--global-line-height);
    margin: 0 0 var(--spacer-sm);
    text-align: center;
  }
}

.empty-wishlist {
  display: flex;
  flex: 1;
  flex-direction: column;

  &__banner {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__label,
  &__description {
    text-align: center;
  }
  &__label {
    --heading-description-margin: 0 0 var(--spacer-xl) 0;
    --heading-title-margin: 0 0 var(--spacer-xl) 0;
    --heading-title-color: var(--c-primary);
    --heading-title-font-weight: var(--font-weight--semibold);
    @include for-desktop {
      --heading-title-font-size: var(--font-size--xl);
      --heading-title-margin: 0 0 var(--spacer-sm) 0;
    }
  }
  &__icon {
    --image-width: 16rem;
    margin: 0 0 var(--spacer-2xl) 7.5rem;
  }
}
.heading {
  &__wrapper {
    --heading-title-color: var(--c-link);
    --heading-title-font-weight: var(--font-weight--semibold);
    display: flex;
    justify-content: space-between;
  }

  &__close {
    color: var(--c-gray-variant);
    cursor: pointer;
  }
}

.sidebar-bottom {
  margin: auto 0 0 0;
}

.collected-product-list {
  flex: 1;

  .sf-collected-product {
    &__aside {
      flex: 0 0 5rem;
    }

    &__image {
      height: auto;
      aspect-ratio: var(--product-image-ratio);
    }
  }
}

.sf-collected-product {
  margin: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  --collected-product-width: 100%;

  &__add-to-cart {
    position: absolute;
    right: 0;
    bottom: 36px;
    --button-width: 100px;
    --button-height: var(--spacer-lg);
    --button-padding: var(--spacer-2xs);

    &-loader {
      --loader-color: var(--white-color);
    }

    @include for-desktop {
      bottom: 52px;
    }
  }

  &__add-to-cart-loader {
    --loader-overlay-background: transparent;

    svg {
      width: auto;
      height: 100%;
    }
  }

  .collected-product__configurable-options {
    list-style: none;
    margin: 0 auto 0 0;
    padding: 0 var(--spacer-2xs);
    font-size: var(--font-size-10);
    line-height: var(--spacer-18);
    color: var(--gray-primary-color);
    flex: 1;

    li {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    @include for-desktop {
      font-size: var(--font-size-13);
    }
  }

  &__actions {
    transition: opacity 150ms ease-in-out;
  }

  &__save,
  &__compare {
    --button-padding: 0;

    &:focus {
      --cp-save-opacity: 1;
      --cp-compare-opacity: 1;
    }
  }

  &__save {
    opacity: var(--cp-save-opacity, 0);
  }

  &__compare {
    opacity: var(--cp-compare-opacity, 0);
  }

  .sf-badge__absolute {
    position: absolute;
  }
}

.sf-notification--wishlist {
  --notification-font-size: var(--font-size-sm);
}
</style>
